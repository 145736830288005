<template>
  <div class="full-page overflow-auto" ref="container">
    <FavorContainer
      v-if="flag('stage_6_clear') && !flag('stage_6_clear_favor')"
      @close="clearbonusDone"
      :stage="stage"
      :step="3"
      :stageFinished="true"
      :maximum_step="1"
    ></FavorContainer>
    <div class="position-relative stage-content" ref="stage">
      <stageHeader :character="character" :stageLocation="stageLocation"></stageHeader>
      <moreContent @click="showProgressUpdate = false" v-if="showProgressUpdate"></moreContent>
      <checkPosition
        :location="check1stPosition"
        :local="[25.042456, 121.507745]"
        :stage_id="6"
        @checkPositionEvent="checkPosition"
      ></checkPosition>
      <!-- <div class="unlock-block position-relative">
        <a role="button" class="hint-20">?</a> -->
      <!-- <div class="unblock-inside d-flex position-relative justify-content-center">
          <a class="d-flex align-items-center justify-content-center" @click="checkPosition">
            <div>
              <img class="" src="/img/icon-finish.svg" alt="" />
              <div class="mt-2 icon-intro">抵達地點後<br />點擊繼續</div>
            </div>
          </a>
        </div> -->
      <!-- </div> -->
      <template v-if="flag('stage_6_check1stPlace')">
        <igStoryGoGame :video="phase1IgStory" @goGame="goGame"></igStoryGoGame>
        <stageMessageNoInput :data="phase1IgStoryMsg"></stageMessageNoInput>
        <div v-if="flag('stage_6_datingRule')" class="d-flex position-relative justify-content-center py-5">
          <a class="d-flex flex-column align-items-center justify-content-center" @click="goDatingRule">
            <div class="text-center">
              <img class="" src="/img/icon-finish.svg" alt="" />
              <div class="mt-2 icon-intro">繼續關卡</div>
            </div>
          </a>
        </div>
      </template>
      <template v-if="flag('stage_6_datingFinish')">
        <winnerCharacter :winnerCharacter="flag('stage_6_datingWinner')" :winnerTalk="winnerTalk"></winnerCharacter>
        <photoMission
          :photo="photoMission"
          :stage="stage"
          @final="final"
          @next="next"
          @showStageFinishedFavor="showStageFinishedFavor"
        >
          <stageMessageNoInput :data="photoMissionMsg"></stageMessageNoInput>
        </photoMission>
      </template>
    </div>
    <mainMenu></mainMenu>
  </div>
</template>

<script>
import Avatar from "../Mixins/Avatar";
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import StageFromMixin from "../Mixins/StageFromMixin";
import getDistance from "../Mixins/getDistance";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
import igStoryGoGame from "@/views/part/igStoryGoGame";
import winnerCharacter from "@/views/part/winnerCharacter";
import moreContent from "@/views/part/moreContent";
import checkPosition from "@/views/part/checkPosition";
import photoMission from "@/views/part/photoMission";
import mainMenu from "@/views/part/mainMenu";
import stageHeader from "@/views/part/stageHeader";

import { mapGetters } from "vuex";
import FavorContainer from "./favor/FavorContainer";
import UserFromVuex from "../Mixins/UserFromVuex";
import ShowUpdate from "@/Mixins/ShowUpdateMixin";
import ModalMixin from "@/Mixins/ModalMixin";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import {getCurrentPosition} from "../libs/getCurrentPosition";

export default {
  name: "ximen",
  mixins: [
    Avatar,
    ShowUpdate,
    CharacterFromVuex,
    WebSocketMixin,
    StageFromMixin,
    getDistance,
    UserFromVuex,
    ModalMixin,
    TeamFromVuex
  ],
  components: {
    photoMission,
    mainMenu,
    stageHeader,
    stageMessageNoInput,
    igStoryGoGame,
    winnerCharacter,
    moreContent,
    FavorContainer,
    checkPosition
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    character() {
      return this.getRoleByName(this.siteCharacter.name);
    },
    winnerTalk() {
      switch (this.flag("stage_6_datingWinner")) {
        case "小希":
          return "只要是充滿真誠的話都討人喜歡呀";
        case "花輪":
          return "我犯了偷竊罪，因為我偷了你的心！";
        case "赤木":
          return "我是第一名拉！叫我情聖";
        case "白爛":
          return "你們連約會都不會，唉太弱了";
        case "春哥":
          return "妳在想什麼，我都能懂";
        case "胖子":
          return "女人心理學這門學科也必須是A+啊！";
        default:
          return "";
      }
    }
  },
  data() {
    return {
      showProgressUpdate: false,
      showProgressUpdateFlags: ["stage_6_check1stPlace"],
      stage: {
        stage_id: 6
      },
      siteCharacter: {
        name: "花輪"
      },
      stageLocation: {
        time: "高三上",
        location: "西門町"
      },
      check1stPosition: {
        position: [25.042456, 121.507745],
        distance: 50
      },
      phase1IgStory: {
        src: "S05",
        poster: "mc/10.jpg",
        autoplay: false,
        stageFlag: "stage_6_goDating"
      },
      phase1IgStoryMsg: [
        {
          name: "花輪",
          content: "好巧喔！你們也來西門町嗎？我們也是耶！剛考完學測就是要看電影呀"
        },
        {
          name: "小桃",
          content: "🙂"
        },
        {
          name: "花輪",
          content: "你們在約會嗎？我們保證不會打擾你們的！"
        },
        {
          name: "赤木",
          content: "我們自己玩自己的！不用擔心、不用擔心！"
        },
        {
          name: "小桃",
          content: "那…小彥，我們先去買飲料？！"
        },
        {
          name: "花輪",
          content: "木瓜牛奶！養顏美容！"
        },
        {
          name: "白爛",
          content: "酸梅湯！梅子可樂！酸酸甜甜～戀愛的感覺"
        },
        {
          name: "小桃",
          content: "我才沒有問你們勒！😑"
        },
        {
          name: "花輪",
          content: "那時候偷偷跟在你們後面觀察，真的是笑死我！" + "要是換成我這種情場高手，一定早就追到手了啦！"
        }
      ],
      photoMission: {
        img: "photo-ximen.jpg",
        finish: "stage_6_photo_clear",
        flag: "stage_6_clear",
        local: [25.045257, 121.504079],
        flyTo: [25.044944, 121.504014]
      },

      photoMissionMsg: [
        {
          name: "花輪",
          content: "我原本以為我喜歡的是贏的感覺，後來才發現我真的喜歡妳"
        },
        {
          name: "提示",
          content:
            "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
        }
      ]
    };
  },
  mounted() {
    this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.showProgressUpdate) {
        this.showProgressUpdate = false;
      }
    },
    final() {
      this.unlockStage(6);
    },
    next() {
      this.setFlag({ key: "stage_6_photo_clear", status: true });
    },
    checkPosition() {
      //隊長才能開始
      // if (!this.isLeader) {
      //   this.showModal("隊長才能開始");
      //   return;
      // }
      console.log("checkPosition");
      if (navigator.geolocation) {
        getCurrentPosition(
          position => {
            let p = position.coords;
            console.log(p.latitude);

            let userCenter = [p.latitude, p.longitude];
            // console.log(this.center)
            // console.log(this.userCenter)
            let distance = this.getDistance(
              userCenter[0],
              userCenter[1],
              this.check1stPosition.position[0],
              this.check1stPosition.position[1],
              "M"
            );
            console.log(distance);
            if (distance < this.check1stPosition.distance) {
              // this.$router.replace("datingRule");

              this.setFlag({ key: "stage_6_check1stPlace", status: true });
            } else {
              this.showModal("你還沒到喔");
              // alert("你還沒到喔");
              //測試之後要刪掉
            }
          },
          err => {
            alert(err.message);
          },
          {
            timeout: 5000
          }
        );
      } else {
        alert("您的瀏覽器不支援地理定位");
      }
    },
    goGame() {
      this.setFlag({ key: "stage_6_datingRule", status: true });
      // const data = {
      //   method: "EnterStage",
      //   token: this.token,
      //   location: {
      //     link: "datingRule"
      //   }
      // };
      // sendMessage(data);
    },
    goDatingRule() {
      if (this.flag("stage_6_datingFinish")) {
        this.$router.replace("datingResult");
        return;
      }
      this.$router.replace("datingRule");
    },
    clearbonusDone: function() {
      this.setFlag({ key: "stage_6_clear_favor", status: true });
      this.unlockStage(6);
    },
    showStageFinishedFavor() {
      this.setFlag({ key: "stage_6_clear", status: true });
    }
  }
};
</script>

<style scoped></style>
